<template>
  <div eagle-tree="root" class="py-4">
    <div v-if="pageTitle" class="text-h5 mb-2">
      {{pageTitle}}
    </div>

    <div v-if="treeEmpty" class="grey--text text--darken-2">
      {{'data.node.empty'| t}}
    </div>

    <v-treeview
      v-if="treeData"
      ref="nestSetTree"
      dense
      hoverable
      :open-all="openAll"
      transition
      return-object
      :items="treeData"
    >
      <template v-slot:label="{item, open}">
        <tree-node
          :tree-key="treeKey"
          :open="open"
          :item="item"
        ></tree-node>
      </template>
    </v-treeview>

    <treeAction
      @toggleOpen="toggleOpen"
      :treeKey="treeKey"
    ></treeAction>

    <data-not-found
      v-if="loadFailure"
    ></data-not-found>
  </div>
</template>

<script lang="babel" type="text/babel">
export default {
  props: {
    treeKey: String,
  },
  data: () => ({
    open: [],
    openAll: true,
  }),
  methods: {
    toggleOpen() {
      const $tree = this.$refs.nestSetTree
      if(!$tree) return
      this.openAll = !this.openAll
      $tree.updateAll(this.openAll)
    },
  },
  computed: {
    pageTitle() {
      return this.$store.getters[`tree/${this.treeKey}/pageTitle`]
    },
    treeEmpty() {
      return this.$store.getters[`tree/${this.treeKey}/treeEmpty`]
    },
    loadFailure() {
      return this.$store.getters[`tree/${this.treeKey}/loadFailure`]
    },
    treeData: {
      get() {
        return this.$store.getters[`tree/${this.treeKey}/data`]
      },
      set(value) {
        this.$store.dispatch(`tree/${this.treeKey}/setData`, value)
      },
    },
  },
  components: {
    treeNode: () => import('@/components/tree/treeNode.vue'),
    treeAction: () => import('@/components/tree/treeAction.vue'),
  },
}
</script>

<style lang="sass" type="text/sass" scoped>
*[eagle-tree="root"]
  min-height: 80vh
</style>